import { render, staticRenderFns } from "./SupportDialog.vue?vue&type=template&id=740bc1d5&scoped=true"
import script from "./SupportDialog.vue?vue&type=script&lang=js"
export * from "./SupportDialog.vue?vue&type=script&lang=js"
import style0 from "./SupportDialog.vue?vue&type=style&index=0&id=740bc1d5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "740bc1d5",
  null
  
)

export default component.exports